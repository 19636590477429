import React from 'react'
import { BiMoney } from "react-icons/bi";
import {  FaRegUserCircle, FaUsers } from "react-icons/fa";
import { IoMdTrendingUp } from "react-icons/io";
import { BsJournalArrowUp } from "react-icons/bs";
import { TiInfo } from "react-icons/ti";
import SideNav from './SideNav';
import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { UserContext } from "../App";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { OrderContext } from "../App";
import { Link, Navigate } from 'react-router-dom';

const Dashboard = () => {
  axios.defaults.withCredentials = true;
  const user = useContext(UserContext); 
  // const orders = useContext(OrderContext); 
  
  const [data, setData] = useState([]);
  const [title, setTitle] = useState([])
  const [services, setServices] = useState([])
  const [charge, setPrice] = useState('')
   const [description, setDescription] = useState("");
  const [username, setUsername] = useState("");
  // const [order_id, setOrder_id] = useState('');
  const [link, setLink] = useState('');
  const [order_id, set_id] = useState('');
  // const [, setPrice] = useState([]);
  
  
  
 const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (user) {
      axios
        .post(
          "https://backend-production-8016.up.railway.app/orders/order/user",
          {
            userid: user._id,
          }
        )
        .then((resp) => {
          if (resp) {
            const sortedData = [...resp.data];
            setOrders(sortedData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

 
  useEffect(() => {
    axios
      .get("https://backend-production-8016.up.railway.app/Orders")
      .then((resp) => {
        if (resp) {
          // console.log(resp.data);
          setData(resp.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = ((event) => {

    setTitle(event.target.value)
    const [val] = data.filter((item) => item.title === event.target.value)
    if (val) {
      // console.log(val);
      setServices(val.services)
      set_id(val._id);
}
  })
  
  const setChange = ((event) => {

    // setOrder_id(event.target.value)
    const val = services.find((item) => item.title === event.target.value)
    if (val) {
      // console.log(val);
  setPrice(val.price)
      setDescription(val.description);
      
}
  })

     const handleSubmit = async (event) => {
       event.preventDefault();
       try {
         const data = {
           order_id,
           link,
           charge,
         };

         const resp = await axios.post(
           "https://backend-production-8016.up.railway.app/orders/create-order",
           data
         );


        //  console.log(resp);
         if (resp.status === 200) {
           toast.success(resp.data.message, {
             position: "top-right",
             autoClose: 100,
             hideProgressBar: true,
             closeOnClick: false,
             pauseOnHover: false,
             draggable: false,
             progress: undefined,
             theme: "light",
           });

            window.location.href = "/Dashboard";
         }


       } catch (error) {
        //  console.log(error);
         toast.error(error.response.data.message, {
           position: "top-right",
           autoClose: 100,
           hideProgressBar: true,
           closeOnClick: false,
           pauseOnHover: false,
           draggable: false,
           progress: undefined,
           theme: "light",
         });
       }
     };

  
      // const List = [
      //   "Choose a category",
      //   "Chinese Promotions", 
      //   "Project Listing and Trending",
      //   "Telegram Calls Channel Promotions",
      //   "Telegram Bots",
      //   "Telegram Upvotes",
      //   "Twitter Promotions",
      //   "Youtube Cryp,to Promoions",
      //   "Youtube NFT Promoions",   
      // ];
      // const items = {
      //   "Chinese Promotions": [
      //     "Weibo",
      //     "Wechat",
      //     "BTOK",
      //     "KakaoTalk",
      //     "Douyu",
      //     "Xueqiu",
      //     "Youku",
      //   ],
      //   "Telegram Calls Channel Promotions": ["gfg", "hccccc", "yddddddd"],
      //   "Project Listing and Trending": [
      //     "Dextool",
      //     "CoinMarketCap",
      //     "PinkSale",
      //     "CoinGeko",
      //     "Top100Tokens",
      //   ],
      //   "Telegram Bots": ["Up Votes Bots", "Shilling Bots", "PinkSale Bots"],
      //   "Telegram Upvotes": [
      //     "Coin Scope",
      //     "Coin Snipper",
      //     "Coin mooner",
      //     "Coin Hunters",
      //     "Coin Vote",
      //     "Gem Finder",
      //     "Coin Discovery",
      //     "Watch.Guru",
      //   ],
      //   "Twitter Promotions": ["nnnn"],
      //   "Youtube Crypto Promoions": [
      //     "The Modern Investors - https://youtube.com/@TheModernInvestor  - 228K Youtube subscribers",
      //     "Ivan On Tech - https://youtube.com/@IvanOnTech  - 490K Youtube subscribers",
      //     "JRNY Crypto - https://youtube.com/@JRNYCrypto  - 643K Youtube subscribers",
      //     "BitBoy Crypto - https://youtube.com/@BitBoyCryptoChannel - 1.4M Youtube subscribers",
      //     "Coin Bureau - https://youtube.com/@CoinBureau - 2.1M Youtube subscribers",
      //   ],
      //   "Youtube NFT Promoions": ["gkgkgk"],
      // };
      
   if (!user) {
     return (
       <div className="flex justify-center h-screen items-center">
         <div className="text-xl font-semibold">
           <p className="text-5xl font-bold">ERROR 404</p>

           <p>
             Please Re-login With your pc(laptop){" "}
             <Link to="/" className="text-blue-600">
               click here
             </Link>
           </p>
         </div>
       </div>
     );
   }
      
    return (
      <div>
        <SideNav>
          <div className="bg-gray-100">
            <div className="md:w-[75vw] px-5 md:px-10 md:pt-10">
              <p className="pt-4 uppercase gap-2 text-lg">
                WELCOME <b className="text-sm">{user?.username}</b>
              </p>
              <div className="block md:flex justify-between pt-5 py-2">
                <section className="w-full md:w-[30%] shadow-xl rounded-sm text-white bg-[#383f68] flex  items-center h-20 md:h-[25vh] pl-5 font-bold">
                  <p>
                    <BiMoney className="md:w-14 md:h-14 w-7 h-7" />
                  </p>
                  <div className="md:mt-0 mt-5 md:ml-2">
                    <p className="md:text-xl text-[15px] md:ml-0 ml-2 flex items-center gap-2">
                      USD <a>${user?.balance}</a>
                    </p>
                    <p className="text-sm text-gray-500">ACCOUNT BALANCE</p>
                  </div>
                </section>

                <section className="w-full md:w-[30%] shadow-xl rounded-sm text-white bg-[#383f68]  flex items-center h-20 md:h-[25vh] pl-5 mt-2 md:mt-0 font-bold">
                  <p>
                    <BsJournalArrowUp className="md:w-14 md:h-14 w-7 h-7 " />
                  </p>
                  <div className="md:mt-0 mt-5 md:ml-2">
                    <p className="md:text-xl text-[15px] md:ml-0 ml-2">
                      {orders.length}
                    </p>
                    <p className="text-sm text-gray-500">TOTAL ORDERS</p>
                  </div>
                </section>

                <section className="w-full md:w-[30%] shadow-xl rounded-sm text-white bg-[#383f68] flex  items-center h-20 md:h-[25vh] pl-5  mt-2 md:mt-0 font-bold">
                  <p>
                    <FaRegUserCircle className="md:w-14 md:h-14 w-7 h-7" />
                  </p>
                  <div className="md:mt-0 mt-5 md:ml-2">
                    <p className="md:text-xl text-[15px] md:ml-0 ml-2">
                      JUNIOR
                    </p>
                    <p className="text-sm text-gray-500">ACCOUNT STATUS</p>
                  </div>
                </section>
              </div>

              <div className="py-10 flex gap-10">
                <section className="w-full  md:w-[50%] shadow-xl rounded-sm text-white bg-[#383f68] flex  items-center h-20 md:h-[25vh] pl-5  mt-2 md:mt-0 font-bold">
                  <p>
                    <FaUsers className="md:w-14 md:h-14 w-7 h-7" />
                  </p>
                  <div className="md:mt-0 mt-5 md:ml-2">
                    <p className="md:text-xl text-[15px] md:ml-0 ml-2">17,552</p>
                    <p className="text-sm text-gray-500">TOTAL USERS</p>
                  </div>
                </section>

                <section className="w-full  md:w-[50%] shadow-xl rounded-sm text-white bg-[#383f68] flex  items-center h-20 md:h-[25vh] pl-5  mt-2 md:mt-0 font-bold">
                  <p>
                    <IoMdTrendingUp className="md:w-14 md:h-14 w-7 h-7" />
                  </p>
                  <div className="md:mt-0 mt-5 md:ml-2">
                    <p className="md:text-xl text-[15px] md:ml-0 ml-2">
                      155,300
                    </p>
                    <p className="text-sm text-gray-500">COMPLETED ORDERS</p>
                  </div>
                </section>
              </div>

              <div className="pb-10">
                <div className="w-full py-10 text-center shadow-xl rounded-lg text-xl font-bold text-white bg-[#383f68] underline">
                  <Link to="/become-an-investor">
                    <p className="">Join Our Investors Community</p>
                  </Link>
                </div>
              </div>
              {/* <div className=" mt-5">
                <section className="flex justify-between items-center">
                  <p className="font-bold md:text-4xl text-2xl">New Order</p>
                  <button className="bg-[#383f68] md:text-base text-sm px-3 py-2 md:px-5 md:py-2 text-white rounded-md">
                    New Order
                  </button>
                </section>
              </div> */}
              {/* <div className="pb-3">
                <form
                  className="bg-white text-gray-500 px-3 py-5 mt-3 rounded-sm"
                  onSubmit={handleSubmit}
                >
                  <label htmlFor="" className="font-bold text-sm md:text-base ">
                    Category
                  </label>
                  <br />
                  <select
                    name=""
                    id=""
                    onChange={handleChange}
                    className="border-gray-500 border-2  mb-3 w-full h-[3rem] rounded-md outline-none px-3"
                  >
                    {data.map((items) => {
                      return <option>{items?.title}</option>;
                    })}
                  </select>
                  <input
                    type="hidden"
                    name=""
                    id=""
                    value={order_id}
                    className="border-gray-500 border-2  mb-3 w-full h-[3rem] rounded-md outline-none px-3"
                  />
                  <br />

                  <label
                    htmlFor=""
                    className="font-bold text-sm md:text-base mb-2"
                  >
                    Service
                  </label>
                  <br />
                  <select
                    name=""
                    id=""
                    onChange={setChange}
                    className="border-gray-500 border-2  mb-3 w-full h-[3rem] rounded-md outline-none px-3"
                  >
                    {services.map((items) => {
                      return <option>{items.title}</option>;
                    })}
                  </select>

                  <br />

                  <label
                    htmlFor=""
                    className="font-bold text-sm md:text-base mb-2"
                  >
                    Description
                  </label>
                  <br />
                  <textarea
                    readOnly
                    value={description}
                    type=""
                    className="border-gray-500 border-2  mb-3 w-full h-[15rem] rounded-md outline-none p-3"
                  />
                  <br />

                  <label
                    htmlFor=""
                    className="font-bold text-sm md:text-base mb-2"
                  >
                    Project Description/Tempelate
                  </label>
                  <br />
                  <textarea
                    placeholder='NOTE : Please Always Write A Description About Your Project Or Ensure To Insert Your Project Shilling Tempelate Here.54 👇👇👇👇'
                    type=""
                    className="border-gray-500 border-2  mb-3 w-full h-[15rem] rounded-md outline-none p-3"
                  />
                  <br />

                  <div className="md:flex gap-2">
                    <section className="md:w-[50%]">
                      <label
                        htmlFor=""
                        className="flex font-bold text-sm md:text-base items-center gap-2"
                      >
                        Average time <TiInfo />
                      </label>

                      <input
                        readOnly
                        type="text"
                        placeholder="Time assured 7 - 10 minutes"
                        className="border-gray-500 border-2  mb-3 w-full h-[3rem] rounded-md outline-none px-3"
                      />
                    </section>

                    <section className="md:w-[50%]">
                      <label
                        htmlFor=""
                        className="font-bold text-sm md:text-base"
                      >
                        {" "}
                        Project Link
                      </label>
                      <br />
                      <input
                        type="url"
                        value={link}
                        onChange={(event) => setLink(event.target.value)}
                        className="border-gray-500 border-2  mb-3 w-full h-[3rem] rounded-md outline-none px-3"
                      />
                    </section>
                  </div>

                  <div className="md:flex gap-2">
                    <section className="md:w-[50%]">
                      <label
                        htmlFor=""
                        className="font-bold text-sm md:text-base mb-2"
                      >
                        Charge
                      </label>
                      <br />
                      <div className="border-gray-500 flex items-center border-2  mb-3 w-full h-[3rem] rounded-md outline-none px-3">
                        $
                        <input
                          readOnly
                          type="text"
                          className=" w-full rounded-md outline-none"
                          value={charge}
                        />
                      </div>
                    </section>
                  </div>

                  <button className="bg-[#383f68] text-white w-[7rem] md:w-[10rem] h-[3rem] rounded-lg">
                    Submit
                  </button>
                </form>
              </div> */}
            </div>
          </div>
          <ToastContainer />
        </SideNav>
      </div>
    );
}

export default Dashboard