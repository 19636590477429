import React from 'react'
import SideNav from '../Dashboard/SideNav'
import { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from 'react-router-dom';
import { UserContext } from "../App";
import { Balance } from '@mui/icons-material';



const AddFunds = () => {
    axios.defaults.withCredentials = true;
    const user = useContext(UserContext); 
  const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      // setOpen(false);
  };
  


    const handleOpen = () => {
      setOpen(true);
      if (!address || !amount || !payment_method, amount > 49) {
        setOpen(true);
                     setTimeout(() => {
                        setOpen(DisplayOpen);
                       setOpen(true);
                     }, 3000); 
      } else {
        setOpen(false);

      }

    };

    const [display, setDisplay] = React.useState(false);
  const DisplayOpen = () => {
     setDisplay(true);
    };
  const DisplayClose = () => {
         setTimeout(() => {
           setDisplay(false);
           setOpen(true);
           //  setOpen(true);
         }, 3000); 
    
    
     setTimeout(() => {
       setOpen(handleClickOpen);
       //  setOpen(true);
     }, 5000); 
    };
 
    const [show, setShow] = React.useState(false);
    const [showw, setShoww] = React.useState(false);

    const handleClickOpen = () => {
      setShow(true);
    };

  const handleClickClose = () => {
       setTimeout(() => {
         window.location.href = "/Dashboard";
         setShow(false);
       }, 3000); 
 
 
       
    };
  
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const styles = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


 


  const [price, setPrice] = useState('')
  const [data, setData] = useState('')
  
     const [amount, setAmount] = useState("");
     const [address, setAddress] = useState("");
     const [payment_method, setPayment_method] = useState("900");

  const handleSubmit = async (e) => {
 e.preventDefault();
 try {
     const data = {
       amount,
       address,
       payment_method
     };
     
     const resp = await axios.post(
       "https://backend-production-8016.up.railway.app/Users/addTransaction",
       data
     );
     console.log(resp);
     if (resp.status === 200) {
       toast.success(resp.data.message, {
         position: "top-right",
         autoClose: 1000,
         hideProgressBar: true,
         closeOnClick: false,
         pauseOnHover: false,
         draggable: false,
         progress: undefined,
         theme: "light",
       });
      
     }
   } catch (error) {
     console.log(error);
     toast.error(error.response.data.message, {
       position: "top-right",
       autoClose: 1000,
       hideProgressBar: true,
       closeOnClick: false,
       pauseOnHover: false,
       draggable: false,
       progress: undefined,
       theme: "light",
     });
   }

  }


  const enter = () => {
     try {
       if (price > user?.balance) {
         setData("Insufficent Balance");
       } else if (price <= user?.balance & price > 5) {
         setData("Withdrawal Processing");
           setOpen(true);
        setTimeout(() => {
          setOpen(false)
          setShoww(true)
        }, 5000);
       } if (price == "") {
         setData("")
         
       }
     } catch (error) {
       console.log(error);
    }
    
    
  }


  if (!user) {
    return (
      <div className="flex justify-center h-screen items-center">
        <div className="text-xl font-semibold">
          <p className="text-5xl font-bold">ERROR 404</p>

          <p>
            Please Re-login{" "}
            <Link to="/" className="text-blue-600">
              click here
            </Link>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div>
      <SideNav>
        <div className="p-5 md:p-10">
          <p className="md:text-2xl md:mb-7 mb-3 uppercase font-semibold md:ml-">
            Add Funds
          </p>

          <div className="md:flex gap-2">
            <section className="border-gra-500 border-2 md:w-[60%] p-3 shadow-md">
              <p className="md:text-xl uppercase mb-1 md:mb-3 font-semibold ">
                {" "}
                Payment Made in Crypto
              </p>
              <label htmlFor="">Payment Method</label> <br />
              <select
                value={address}
                className="border-gray-500 mb-2 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem] md:mb-5"
                onChange={(event) => setAddress(event.target.value)}
              >
                <option value="">-- Select Payment Method --</option>
                <option value="bc1qkt44khy92v4xkg8ccxltqhq64cvy8yvmssdgmg - BTC">
                  Bitcoin
                </option>
                <option value="0x45974e9C37aec6788D67740960f07EF0C699283E - BNB">
                  Bnb
                </option>
                <option value="0x45974e9C37aec6788D67740960f07EF0C699283E - ETH">
                  Eth
                </option>
                <option value="D1uTb41xUJGiYWihehH9Rqo32EwEDconbkLQNLkyUQvU - SOL">
                  Solana
                </option>
                <option value="TKkn2g5fxx2qFm33UfRBGym41MXbQAQKZK - TRC 20">
                  Ustd trc 20
                </option>
              </select>
              <br />
              <label htmlFor="">Payment Address</label> <br />
              <input
                hidden
                value={payment_method}
                type="text"
                className="border-gray-500 mb-2 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem] md:mb-5"
              />
              <input
                readOnly
                value={address}
                // onChange={(event) => setAddress(address)}
                type="text"
                className="border-gray-500 mb-2 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem] md:mb-5"
              />
              <br />
              <ToastContainer />
              <label htmlFor="">Amount</label> <br />
              <input
                onChange={(event) => setAmount(event.target.value)}
                value={amount}
                type="number"
                className="border-gray-500 mb-1 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem]"
              />
              <p className="text-red-500 text-sm font-semibold">
                Payment Must Be $100 And Above
              </p>
              <button
                className="bg-[rgb(56,63,104)] mt-2 md:mt-5 text-white px-5 py-2 md:px-10 rounded-md md:py-2 md:text-lg md:mb-0 mb-2"
                onClick={handleOpen}
                // onClick={DisplayOpen}
              >
                Pay
              </button>
            </section>

            <section className="border-2 mt-5 md:mt-0 text-sm md:w-[40%] p-3 shadow-md">
              <p className="mt-2">
                <span className="text-red-700 text-base font-bold">
                  IMPORTANT NOTE :{" "}
                </span>
                Pay exactly the amount you have imputed to pay, do not pay
                lesser or higher than the amount you have placed and please copy
                the exactly Blockchain wallet address.
              </p>

              <p className="mt-5">
                Payments are updated instantly but in some cases where it is
                taking too much time to update, kindly wait for 30minutes -
                1hours, it will be updated, if after 2hours and it has not been
                completed, kindly send a message to any of our Live Chat
                Supports.
              </p>
            </section>
          </div>
        </div>

        <form className="p-5 md:p-10">
          <p className="md:text-2xl md:mb-7 mb-3 uppercase font-semibold md:ml-">
            Withdraw Funds
          </p>

          <div className="md:flex gap-2">
            <section className="border-gra-500 border-2 md:w-[100%] p-3 shadow-md">
              <p className="md:text-xl uppercase mb-1 md:mb-3 font-semibold ">
                {" "}
                Withdrawal Made in Crypto
              </p>
              <label htmlFor="">Withdrawal Method</label> <br />
              <select className="border-gray-500 mb-2 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem] md:mb-5">
                <option value="">-- Select Payment Method --</option>
                <option value="bc1qhzvxxatlrl5evdqnpjk8tvwl98wl6w58w9apzw - BTC">
                  Bitcoin
                </option>
                <option value="0xFE8E9C44416668C34FB7717A0497e1Cec8f2b1b0 - BNB">
                  Bnb
                </option>
                <option value="0xFE8E9C44416668C34FB7717A0497e1Cec8f2b1b0 - ETH">
                  Eth
                </option>
                <option value="2UmzXkLERSGSLQMLktKqFVM3E8RvxNeSfbPLUtjTecSA - SOL">
                  Solana
                </option>
                <option value="TYo61NKrVGze26LgXoWp5J2xGMeTkTg6AN - TRC 20">
                  Ustd trc 20
                </option>
              </select>
              <br />
              <label htmlFor="">Withdrawal Address</label> <br />
              <input
                hidden
                required
                type="text"
                className="border-gray-500 mb-2 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem] md:mb-5"
              />
              <input
                required
                type="text"
                className="border-gray-500 mb-2 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem] md:mb-5"
              />
              <br />
              <ToastContainer />
              <label htmlFor="">Amount</label> <br />
              <input
                required
                type="number"
                value={price}
                onChange={(event) => setPrice(event.target.value)}
                className="border-gray-500 mb-1 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem]"
              />
              <p className="text-red-500 text-sm font-semibold">{data}</p>
              <button
                onClick={enter}
                className="bg-[rgb(56,63,104)] mt-2 md:mt-5 text-white px-5 py-2 md:px-10 rounded-md md:py-2 md:text-lg md:mb-0 mb-2"
              >
                Withdraw
              </button>
            </section>

            {/* <section className="border-2 mt-5 md:mt-0 text-sm md:w-[40%] p-3 shadow-md">
              <p className="mt-2">
                <span className="text-red-700 text-base font-bold">
                  IMPORTANT NOTE :{" "}
                </span>
                Pay exactly the amount you have imputed to pay, do not pay
                lesser or higher than the amount you have placed and please copy
                the exactly Blockchain wallet address.
              </p>

              <p className="mt-5">
                Payments are updated instantly but in some cases where it is
                taking too much time to update, kindly wait for 30minutes -
                1hours, it will be updated, if after 2hours and it has not been
                completed, kindly send a message to any of our Live Chat
                Supports.
              </p>
            </section> */}
          </div>
        </form>

        <div>
          <Modal
            className="md:block hidden"
            open={display}
            onClose={DisplayClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Transaction Details
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <form action="" onSubmit={handleSubmit}>
                  <input
                    hidden
                    required
                    type="text"
                    className="border-gray-500 mb-2 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem] md:mb-5"
                  />
                  <label htmlFor="" className="text-sm font-semibold">
                    Payment Address
                  </label>
                  <input
                    value={address}
                    type="text"
                    readOnly
                    required
                    className=" mb-2 font font-extrabold md:font-bold text-sm md:text-base outline-none rounded-md  w-[90%] md:w-[100%]  md:mb-5"
                  />
                  <label htmlFor="" className="text-sm font-semibold">
                    Amount
                  </label>
                  <div className="flex items-center gap-2">
                    <p className=" font-extrabold  md:font-bold text-sm md:text-base">
                      USD:
                    </p>
                    <input
                      required
                      value={amount}
                      type="text"
                      className=" font-extrabold md:font-bold text-sm md:text-base  outline-none rounded-md  w-[90%] md:w-[100%] "
                    />
                  </div>
                  <button
                    className="bg-[rgb(56,63,104)] mt-2 md:mt-5 w-full text-white px-5 py-2 md:px-10 rounded-md md:py-2 md:text-base md:mb-0 mb-2"
                    // onClick={handleClickClose}
                    onClick={DisplayClose}
                  >
                    I have made this bank transfer
                  </button>
                </form>
              </Typography>
            </Box>
          </Modal>
          <Modal
            className="md:block hidden"
            open={showw}
            onClose={DisplayClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                <p className="text-center uppercase font-semibold">
                  Withdrawal Approved
                </p>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <form action="">
                  <div className="my-5 flex justify-center">
                    <img
                      src="https://cdn-icons-png.freepik.com/256/5610/5610944.png"
                      alt=""
                      className="w-[30%]"
                    />
                  </div>
                  <p>
                    Your request for withdrawal has been received, it will be
                   processed and sent to your wallet shortly in 5 - 10 minutes.
                  </p>

                  <button
                    className="bg-[rgb(56,63,104)] mt-2 md:mt-5 w-full text-white px-5 py-2 md:px-10 rounded-md md:py-2 md:text-base md:mb-0 mb-2"
                    // onClick={handleClickClose}
                    onClick={handleClickClose}
                  >
                    Continue
                  </button>
                </form>
              </Typography>
            </Box>
          </Modal>
        </div>

        <div>
          <Modal
            className="md:hidden block"
            open={display}
            onClose={DisplayClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styles}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Transaction Details
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <form action="" onSubmit={handleSubmit}>
                  <input
                    hidden
                    value={payment_method}
                    type="text"
                    className="border-gray-500 mb-2 border-2 outline-none rounded-md px-2 text-base w-[90%] md:w-[100%] md:h-[2.5rem] h-[2rem] md:mb-5"
                  />
                  <label htmlFor="" className="text-sm">
                    Payment Address
                  </label>
                  <input
                    type="text"
                    value={address}
                    className=" mb-2 font font-extrabold md:font-bold text-[80%] md:text-base outline-none rounded-md h-[1] w-[100%]  md:mb-5"
                  />
                  <label htmlFor="" className="text-sm">
                    Amount
                  </label>
                  <div className="flex items-center gap-2">
                    <p className=" font-extrabold  md:font-bold text-sm md:text-base">
                      USD:
                    </p>
                    <input
                      value={amount}
                      type="text"
                      className=" font-extrabold md:font-bold text-sm md:text-base  outline-none rounded-md  w-[90%] md:w-[100%] md:mb-5"
                    />
                  </div>
                  <button
                    className="bg-[rgb(56,63,104)] mt-2 md:mt-5 w-full text-white px-5 py-2 md:px-10 rounded-md md:py-2 text-sm md:mb-0 mb-2"
                    // onClick={handleClickClose}
                    onClick={DisplayClose}
                  >
                    I have made this bank transfer
                  </button>
                </form>
              </Typography>
            </Box>
          </Modal>
        </div>

        <div>
          <Dialog open={show}>
            <DialogTitle>
              <p className=" font-bold text-black">Payment Transaction Hash</p>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p className="text-sm font-semibold text-black">
                  In other to comfirm payment, please enter your tranaction hash
                  here. We will notify you when payment is successful.
                </p>
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Payment Transaction Hash"
                // type="email"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickClose}>Cancel</Button>
              <Button onClick={handleClickClose}>Submit</Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </SideNav>
    </div>
  );
}

export default AddFunds