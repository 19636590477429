import React from 'react'
import SideNav from './SideNav'
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { UserContext } from "../App";

const Services = () => {
  axios.defaults.withCredentials = true;
  const [data, setData] = useState([]);
  const [title, setTitle] = useState([]);
    const [show, setShow] = useState("");
  const [charge, setCharge] = useState("");
    
    const user = useContext(UserContext);
    const [val, setVal] = useState("");
    const [link, setLink] = useState("");
    const [order_id, set_id] = useState("");
    const [amount, setAmount] = useState("");
    const [price, setPrice] = useState("");
    const [hidden, setHidden] = useState("66382b7dd5cce6048331b617");
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        order_id: hidden,
        link,
        charge: Number(amount),
      };

      const resp = await axios.post(
        "https://backend-production-8016.up.railway.app/orders/create-order",
        data
      );
      //  console.log(resp);
      if (resp.status === 200) {
        toast.success(resp.data.message, {
          position: "top-right",
          autoClose: 100,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.href = "/Dashboard";
        }, 2000);
      }
    } catch (error) {
      //  console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

        const SubmitDetials = async (e) => {
          e.preventDefault();
          try {
            const data = {
              charge: Number(price),
              link,
              order_id: hidden,
            };
            //  payment_method.value = address
            const resp = await axios.post(
              "https://backend-production-8016.up.railway.app/orders/create-order",
              data
            );
            console.log(resp);
            if (resp.status === 200) {
              toast.success(resp.data.message, {
                position: "top-right",
                autoClose: 100,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                window.location.href = "/Dashboard";
              }, 2000);
            }
          } catch (error) {
            console.log(error);
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 100,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        };
  
  //   useEffect(() => {
  //     axios
  //       .get("https://backend-production-8016.up.railway.app/Orders")
  //       .then((resp) => {
  //         if (resp) {
  //           // console.log(resp.data);
  //           setData(resp.data);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, []);
  
  // const [val] = data.filter((item) => item.title);
  
if (!user) {
  return (
    <div className="flex justify-center h-screen items-center">
      <div className="text-xl font-semibold">
        <p className="text-5xl font-bold">ERROR 404</p>

        <p>
          Please Re-login With your pc(laptop){" "}
          <Link to="/" className="text-blue-600">
            click here
          </Link>
        </p>
      </div>
    </div>
  );
}

  return (
    <div>
      <SideNav>
        {/* <div className="p-5 md:p-10">
          <p className="md:text-2xl md:mb-7 mb-3 uppercase font-semibold md:ml-">
            Services
          </p>

          <div className="w-full">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                   
                  </TableRow>
                </TableHead>

                {data.map((items) => {
                  console.log(items);
                  return (
                    <>
                      <div className="bg-[#383f68] p-2">
                        <p>{items?.title}</p>
                      </div>

                      <TableBody>
                        <TableRow
                   
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="text-center">
                            {items?.services?.title}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  );
                })}
               
              </Table>
            </TableContainer>
          </div>
        </div> */}

        <div className="py-10 px-5">
          <p className="text-2xl font-bold">Become An Investor</p>
          <p>
            To become one of our investors, kindly complete the form provided
            below. Investors are presented with legitimate projects for
            acquisition and are granted access to our diverse platforms,
            tailored to their specific interests and preferences.
          </p>
          <ToastContainer />
          <form action="" className="mt-10 bnb p-5" onSubmit={handleSubmit}>
            <label className="font-bold text-sm md:text-base" htmlFor="">
              Full Name
            </label>
            <br />
            <input
              placeholder="Full Name"
              type="text"
              className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[95%] mb-3 md:h-[3rem] h-[2.5rem]"
            />

            <div className="grid grid-cols-2 w-[100%]">
              <section>
                <label className="font-bold text-sm md:text-base" htmlFor="">
                  Email
                </label>
                <br />
                <input
                  placeholder="Email"
                  className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] h-[2.5rem] md:h-[3rem] mb-3"
                />
              </section>
              <section>
                <label className="font-bold text-sm md:text-base" htmlFor="">
                  Telegram Username Link
                </label>
                <br />
                <input
                  value={link}
                  onChange={(event) => setLink(event.target.value)}
                  placeholder="https://t.me/Investors-Grain"
                  className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] h-[2.5rem] md:h-[3rem] mb-3"
                />
              </section>
            </div>
            <div className="mt-5">
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  What Category would you fit in?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Global Investor"
                  name="radio-buttons-group"
                  onChange={(event) => setAmount(event.target.value)}
                >
                  <FormControlLabel
                    value="1500"
                    control={<Radio />}
                    label="Global Investor"
                  />
                  <FormControlLabel
                    value="1200"
                    control={<Radio />}
                    label="Presale Investor"
                  />
                  <FormControlLabel
                    value="1000"
                    control={<Radio />}
                    label="NFT Investor"
                  />
                  <FormControlLabel
                    value="2500"
                    control={<Radio />}
                    label="Global & Pre-Sale Investor"
                  />
                  <FormControlLabel
                    value="3500"
                    control={<Radio />}
                    label="All"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="grid grid-cols-2 justify-center items-center">
              <section className="mt-5">
                <label className="font-bold text-sm md:text-base" htmlFor="">
                  Fee
                </label>
                <br />
                <input
                  hidden
                  value={hidden}
                  onChange={(event) => set_id(event.target.value)}
                  type="text"
                  className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] mb-3 md:h-[3rem] h-[2.5rem]"
                />
                <div
                  className="border-gray-500 border-2 gap-1 rounded-md flex items-center text-base
                     w-[90%] md:w-[90%] h-[2.5rem] px-3 md:h-[3rem] mb-3"
                >
                  <p>$</p>
                  <input
                    readOnly
                    value={amount}
                    onChange={(event) => setCharge(event.target.value)}
                    className="outline-none border-none bg-none w-full h-full"
                  />
                </div>
              </section>
            </div>

            <button className="bg-[rgb(56,63,104)] md:h-[3rem] md:mt-7 text-white px-5 py-2 md:px-7 rounded-md  md:text-lg md:mb-0">
              Proceed
            </button>
          </form>
        </div>
      </SideNav>
    </div>
  );
}

export default Services