import React, { useContext } from "react";
import SideNav from '../Dashboard/SideNav'
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { UserContext } from "../App";

const TelegramCalls = () => {

  axios.defaults.withCredentials = true;
  const user = useContext(UserContext);
  const [val, setVal] = useState("");
  const [charge, setCharge] = useState("");
  const [link, setLink] = useState("");
  const [order_id, set_id] = useState("");
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState("");
  const [hidden, setHidden] = useState("66381e44d5cce60483317aa4");
  const [sub, setSub] = useState("");
  const [group, setGroup] = useState("");
  const [lin, setLin] = useState("");
  
  
    useEffect(() => {
      if (group == "Shill Seals") {
        setSub("30k Subscribers");
        setLin("https://t.me/ShillSeals");
        setAmount("500")
      }
      if (group == "CRYPTO GEMS") {
        setSub("26.5k Subscribers");
        setLin("https://t.me/cryptogems555");
        setAmount("550");
      }
      if (group == "Gollum's Gems") {
        setSub("42.5k Subscribers");
        setLin("https://t.me/gollumsgems");
        setAmount("700");
      }
      if (group == "MOONTOK") {
        setSub("44.6k Subscribers");
        setLin("https://t.me/moonspace");
        setAmount("1,000");
      }
      if (group == "小胖交易中心(Dwen Marketing)") {
        setSub("90.1k Subscribers");
        setLin("https://t.me/Dwen_Exchange");
        setAmount("2,000");
      }
      if (group == "CRYPTO CLASH") {
        setSub("17.7k Subscribers");
        setLin("https://t.me/cryptoclashann");
        setAmount("400");
      }
      if (group == "MAD APES") {
        setSub("77k Subscribers");
        setLin("https://t.me/mad_apes_call");
        setAmount("1,300");
      }
      if (group == "CryptoGold加密金狗打新") {
        setSub("56.3k Subscribers");
        setLin("https://t.me/CryptoGoldcc");
        setAmount("1,000");
      }
    });
    

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        order_id: hidden,
        link,
        charge: Number(amount),
      };

      const resp = await axios.post(
        "https://backend-production-8016.up.railway.app/orders/create-order",
        data
      );
      //  console.log(resp);
      if (resp.status === 200) {
        toast.success(resp.data.message, {
          position: "top-right",
          autoClose: 100,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.href = "/Dashboard";
        }, 2000);
      }
    } catch (error) {
      //  console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const SubmitDetials = async (e) => {
    e.preventDefault();
    try {
      const data = {
        charge: Number(price),
        link,
        order_id: hidden,
      };
      //  payment_method.value = address
      const resp = await axios.post(
        "https://backend-production-8016.up.railway.app/orders/create-order",
        data
      );
      console.log(resp);
      if (resp.status === 200) {
        toast.success(resp.data.message, {
          position: "top-right",
          autoClose: 100,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.href = "/Dashboard";
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    };
    

//  if (!user) {
//    return (
//      <div className="flex justify-center h-screen items-center">
//        <div className="text-xl font-semibold">
//          <p className="text-5xl font-bold">ERROR 404</p>

//          <p>
//            Please Re-login With your pc(laptop){" "}
//            <Link to="/" className="text-blue-600">
//              click here
//            </Link>
//          </p>
//        </div>
//      </div>
//    );
//  }



  return (
    <>
      <SideNav>
        <div>
          <div className="MiddleDiv">
            <div className="py-2 md:mb-0 mb-5 md:w-[70vw] md:py-7 pl-2">
              <div className=" md:w-[75vw] md:block md:h-fit mx-2 md:mr-0 mr-5 px-5 md:mt-5 rounded-xl bnb md:px-7 py-5">
                <ToastContainer />
                <div>
                  <h1 className="text-xl md:mt-0 mt-5 md:mb-0 mb-5 md:text-2xl font-bold">
                    Telegram Calls Channel
                  </h1>
                  <p className=" font-semibold mt-2">
                    Telegram calls channels facilitate increased engagement and
                    publicity for your community or project. They serve not only
                    to enhance purchases but also to foster community growth and
                    engagement. We offer a selection of recommended channels
                    that are guaranteed and trusted, renowned for their
                    effectiveness in driving engagement and sales.
                  </p>
                  <form className=" md:w-[100%] mt-10" onSubmit={handleSubmit}>
                    {/* <label
                        className="font-bold text-sm md:text-base"
                        htmlFor=""
                      >
                        Telegram Channels
                      </label>
                      <br />
                      <input
                        value={link}
                        onChange={(event) => setLink(event.target.value)}
                        placeholder="https://"
                        type="text"
                        className="border-gray-500 border-2 mb-3 outline-none rounded-md px-5 text-sm md:text-ml l w-[90%] md:h-[3rem] h-[2.5rem]"
                      />
                      <br /> */}
                    <label
                      className="font-bold text-sm md:text-base"
                      htmlFor=""
                    >
                      Telegram Channels
                    </label>
                    <br />
                    <select
                      value={group}
                      onChange={(event) => setGroup(event.target.value)}
                      name=""
                      id=""
                      className="border-gray-500 mt-1 border-2 font-semibold outline-none mb-3 px-3 rounded-md text-mll w-[90%] uppercase md:w-[95%] md:h-[3rem] h-[2.5rem]"
                    >
                      <option value="BSS">
                        --- Select Telegram Channel ---
                      </option>
                      <option value="Shill Seals">Shill Seals</option>
                      <option value="CRYPTO GEMS">CRYPTO GEMS</option>
                      <option value="Gollum's Gems">Gollum's Gems </option>
                      <option value="MOONTOK">MOONTOK</option>
                      <option value="小胖交易中心(Dwen Marketing)">
                        小胖交易中心(Dwen Marketing)
                      </option>
                      <option value="CRYPTO CLASH">CRYPTO CLASH</option>
                      <option value="MAD APES">MAD APES</option>
                      <option value="CryptoGold加密金狗打新">
                        CryptoGold加密金狗打新
                      </option>
                      {/* <option value="">MAD APES</option> */}
                    </select>
                    <br />
                    <div className="grid grid-cols-2 w-[100%]">
                      <section>
                        <label
                          className="font-bold text-sm md:text-base"
                          htmlFor=""
                        >
                          Channel Link
                        </label>
                        <br />
                        <p
                          className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] h-[2.5rem] md:h-[3rem]  pt-2"
                        >
                          <a href={lin}>{lin}</a>
                        </p>
                        <p className="mb-3 text-xs font-semibold text-red-500">
                          Click to visit channel
                        </p>
                      </section>
                      <section>
                        <label
                          className="font-bold text-sm md:text-base"
                          htmlFor=""
                        >
                          Channel Subscribers
                        </label>
                        <br />
                        <p
                          className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] h-[2.5rem] md:h-[3rem] mb-3 pt-2"
                        >
                          {sub}
                        </p>
                      </section>
                      <section>
                        <label
                          className="font-bold text-sm md:text-base"
                          htmlFor=""
                        >
                          Price
                        </label>
                        <br />
                        <div
                          className="border-gray-500 border-2 gap-1 rounded-md flex items-center text-base
                     w-[90%] md:w-[90%] h-[2.5rem] px-3 md:h-[3rem] mb-3"
                        >
                          <p>$</p>
                          <input
                            readOnly
                            onChange={(event) => setCharge(event.target.value)}
                            value={amount}
                            className="outline-none border-none bg-none w-full h-full"
                          />
                        </div>
                      </section>
                      <section>
                        <label
                          className="font-bold text-sm md:text-base"
                          htmlFor=""
                        >
                          Website Link
                        </label>
                        <br />
                        <input
                          type="text"
                          className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] mb-3 md:h-[3rem] h-[2.5rem]"
                        />
                      </section>
                      <section>
                        <label
                          className="font-bold text-sm md:text-base"
                          htmlFor=""
                        >
                          Twitter Link
                        </label>
                        <br />
                        <input
                          hidden
                          value={hidden}
                          onChange={(event) => set_id(event.target.value)}
                          type="text"
                          className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] mb-3 md:h-[3rem] h-[2.5rem]"
                        />
                        <input
                          type="text"
                          className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] mb-3 md:h-[3rem] h-[2.5rem]"
                        />
                      </section>
                      <section>
                        <label
                          className="font-bold text-sm md:text-base"
                          htmlFor=""
                        >
                          Chat Link
                        </label>
                        <br />
                        <input
                          type="text"
                          value={link}
                          onChange={(event) => setLink(event.target.value)}
                          className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[90%] mb-3 md:h-[3rem] h-[2.5rem]"
                        />
                      </section>
                    </div>
                    <label
                      className="font-bold text-sm md:text-base"
                      htmlFor=""
                    >
                      Project Tempelate
                    </label>
                    <br />
                    <textarea
                      className="border-gray-500 border-2 outline-none rounded-md p-5 text-base
                     w-[90%] md:w-[95%] mb-3 md:h-[25rem] h-[15rem]"
                    />
                    <br />
                    {/* <div>
                                              
                                            <label
                        className="font-bold text-sm md:text-base"
                        htmlFor=""
                      >
                        HardCap
                      </label>
                      <br />
                      <input
                        // readOnly
                        // placeholder="http://"
                        // value={order_id}
                        // onChange={(event) => set_id(event.target.value)}
                        type="text"
                        className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                      />{" "}
                      <br />
                    

                
                      <label
                        className="font-bold text-sm md:text-base"
                        htmlFor=""
                      >
                        Amount of Investors
                      </label>
                      <br />
                      <select
                        type="text"
                        className="border-gray-500 border-2 mb-3 outline-none rounded-md px-2 text-base l w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)}
                      >
                        <option value="">--- Select Investors ---</option>
                        <option value="180">50 - 70 Assured Investors</option>
                        <option value="350">100 - 250 Assured Investors</option>
                        <option value="550">300 - 500 Assured Investors</option>
                        <option value="800">500 - 700 Assured Investors</option>
                        <option value="1100">1000 Assured Investors</option>
                      </select>
                      <br />
                      <label
                        className="font-bold text-sm md:text-base"
                        htmlFor=""
                      >
                        Price
                      </label>
                      <br />
                      <div
                        className="border-gray-500 border-2 outline-none px-3 rounded-md text-ml 
                    w-[90%] md:w-[80%] mb-3 md:h-[3rem] h-[2.5rem] flex items-center"
                      >
                        <p className="font-semibold mt-[2px]">$</p>
                        <input
                          readOnly
                          // placeholder="All payments made strictly in USD"
                          value={amount}
                          onChange={(event) => setCharge(event.target.value)}
                          name=""
                          id=""
                          className="w-full  outline-none h-full"
                          // readOnly
                        />
                      </div>

                      <label
                        className="font-bold text-sm md:text-base"
                        htmlFor=""
                      >
                        Chat Link
                      </label>
                      <br />
                      <input
                        // readOnly
                        placeholder="http://"
                        // value={order_id}
                        // onChange={(event) => set_id(event.target.value)}
                        type="text"
                        className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                      />
                      <input
                        hidden
                        value={hidden}
                        onChange={(event) => set_id(event.target.value)}
                        type="text"
                        className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                      />
                      <br />
                
            </div> */}

                    <button className="bg-[rgb(56,63,104)] mt-2 md:mt-5 text-white px-5 py-2 md:px-7 rounded-md md:py-3 md:text-lg md:mb-0 mb-2">
                      Proceed
                    </button>
                  </form>
                </div>
                <b className="flex gap-2 items-center mt-5 md:mt-10 md:text-base text-[13px]">
                  <p className="text-yellow-400">Note: </p>
                  <p>It takes 2-3 hours to comfirm work completly done</p>
                </b>
              </div>
            </div>
          </div>
        </div>
      </SideNav>
    </>
  );
}

export default TelegramCalls