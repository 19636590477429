import React from 'react'
import SideNav from '../Dashboard/SideNav';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from 'react';
import axios from 'axios';


const Admin_orders = () => {

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const [sortDirection, setSortDirection] = useState("asc");
  const [data, setData] = useState([])
    useEffect(() => {
      axios
        .get("https://backend-production-8016.up.railway.app/Orders/all")
        .then((resp) => {
          if (resp) {
             console.log(resp.data);
            const sortedData = [...resp.data];
            sortedData.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateB - dateA;
            });

            setData(sortedData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  
  return (
    <div>
      {" "}
      <SideNav>
        <div className="p-5 md:p-10">
          <p className="md:text-2xl uppercase font-semibold md:ml-">
            Users order
          </p>
        </div>

        <TableContainer
          component={Paper}
          className="px-5 md:px-10 pb-5 md:pb-10"
        >
          <Table
            sx={{ minWidth: 700, maxWidth: 1000 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Project Link</StyledTableCell>
                <StyledTableCell align="right">order_category</StyledTableCell>
                <StyledTableCell align="right">price</StyledTableCell>
                <StyledTableCell align="right">user</StyledTableCell>
                <StyledTableCell align="right">date</StyledTableCell>
                <StyledTableCell align="right">status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((items) => (
                <StyledTableRow key={data.name}>
                  <StyledTableCell component="th" scope="row">
                    {items?.link}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {items?.order_category_id?.title}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    ${items?.charge}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {items?.user?.username}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {new Date(items?.createdAt).toLocaleDateString("en-us", {
                      dateStyle: "full",
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {items?.status}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SideNav>
    </div>
  );
}

export default Admin_orders