import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import "./index.css";
import Signup from "./user/Signup";
import axios from "axios";
import StartNavbar from "./Frontend/StartNavbar";
import BottomNav from "./Frontend/BottomNav";
import StartPage from "./Frontend/StartPage";
import DiscordServices from "./services/DiscordServices";
import InnerNavbar from "./Dashboard/InnerNavbar";
import InnerbottomNav from "./Dashboard/InnerbottomNav";
import TelegramServices from "./services/TelegramServices";
import Dashboard from "./Dashboard/Dashboard";
import AddFunds from "./user/AddFunds";
import Orders from "./user/Orders";
import Services from "./Dashboard/Services";
import Add_orders from "./Admin/Add_orders";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import { useEffect, useState, createContext, useContext } from "react";
import Admin_orders from "./Admin/Admin_orders";
import TelegramCalls from "./services/TelegramCalls";
import Chinese from "./services/Chinese";
// import { Dashboard } from "@mui/icons-material";
export const UserContext = createContext();
export const OrderContext = createContext();

function App() {
  axios.defaults.withCredentials = true;
  const [data, setData] = useState(null); 
  // const [order, setOrder] = useState(null); 

 const user = useContext(UserContext);
 const [orders, setOrder] = useState([]);

 useEffect(() => {
   if (user) {
     axios
       .post(
         "https://backend-production-8016.up.railway.app/orders/order/user",
         {
           userid: user._id,
         }
       )
       .then((resp) => {
         if (resp) {
           setOrder(resp.orders);
           const sortedData = [...resp.orders];
           sortedData.sort((a, b) => {
             const dateA = new Date(a.date);
             const dateB = new Date(b.date);
             return dateB - dateA;
           });

           setOrder(sortedData);
         }
       })
       .catch((err) => {
         console.log(err);
       });
   }
 }, [user]);


  useEffect(() => {
    axios
      .get("https://backend-production-8016.up.railway.app/users/profile")
      .then((resp) => {
        if (resp) {
          // console.log(resp.data);
          setData(resp.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  



  return (
    <div className="bg-white">
      <UserContext.Provider value={data}>
        <OrderContext.Provider value={{ orders }}>
          <Routes>
            <Route path="/Signup" element={<Signup />} />
            <Route path="/StartNav" element={<StartNavbar />} />
            <Route path="/BottomNav" element={<BottomNav />} />
            <Route path="/" element={<StartPage />} />
            <Route path="/Admin_orders" element={<Admin_orders />} />
            <Route path="/become-an-investor" element={<Services />} />
            <Route path="/Add_orders" element={<Add_orders />} />
            <Route path="/Orders" element={<Orders />} />
            <Route path="/AddFunds" element={<AddFunds />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/global-investors" element={<TelegramServices />} />
            <Route path="/InnerbottomNav" element={<InnerbottomNav />} />
            <Route path="/InnerNavbar" element={<InnerNavbar />} />
            <Route path="/presale-investors" element={<DiscordServices />} />
            <Route path="/TelegramCalls" element={<TelegramCalls />} />
            <Route path="/chinese-investors" element={<Chinese />} />
          </Routes>
        </OrderContext.Provider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
