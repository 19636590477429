import SideNav from '../Dashboard/SideNav'
import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Add_orders = () => {
 const [title, setTitle] = useState("");
 const [price, setPrice] = useState("");
 const [description, setDescription] = useState("");

  
  
  
  
    const [inputFields, setInputFields] = useState([
      {
        title: "",
        price: "",
        description: "",
      },
    ]);

    const addInputField = () => {
      setInputFields([
        ...inputFields,
        {
          title: "",
          price: "",
          description: "",
        },
      ]);
    };
    const removeInputFields = (index) => {
      const rows = [...inputFields];
      rows.splice(index, 1);
      setInputFields(rows);
    };
    const handleChange = (index, evnt) => {
      const { name, value } = evnt.target;
      const list = [...inputFields];
      list[index][name] = value;
      setInputFields(list);
    };
 
       const handleSubmit = async (event) => {
         event.preventDefault();
         try {
           const data = {
             title,
         services:inputFields
           };

           const resp = await axios.post(
             "https://backend-production-8016.up.railway.app/orders/create-order-category",
             data
           );
           console.log(resp);
           if (resp.status === 200) {
             toast.success(resp.data.message, {
               position: "top-right",
               autoClose: 100,
               hideProgressBar: true,
               closeOnClick: false,
               pauseOnHover: false,
               draggable: false,
               progress: undefined,
               theme: "light",
             });
           }
         } catch (error) {
           console.log(error);
           toast.error(error.response.data.message, {
             position: "top-right",
             autoClose: 100,
             hideProgressBar: true,
             closeOnClick: false,
             pauseOnHover: false,
             draggable: false,
             progress: undefined,
             theme: "light",
           });
         }
       };

  return (
    <SideNav>
      <div className="p-5 md:p-10">
        <p className="md:text-2xl md:mb-7 mb-3 uppercase font-semibold md:ml-">
          Add Service
        </p>
        <form action="" onSubmit={handleSubmit} className="shadow-md p-5">
          <label htmlFor="" className="text-lg font-semibold">
            Title
          </label>
          <br />
          <input
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            type="text"
            className="border-gray-500 border-2 md:w-[21%] mb-3 w-full h-[3rem] rounded-md outline-none px-3"
          />
          <div className="">
            <div className="">
              <div className="">
                {inputFields.map((data, index) => {
                  const { title, description, price } = data;
                  return (
                    <div className="md:flex gap-2" key={index}>
                      <div className="">
                        <div className="form-group">
                          <label htmlFor="" className="text-lg font-semibold">
                            Service
                          </label>
                          <br />
                          <input
                            type="text"
                            onChange={(evnt) => handleChange(index, evnt)}
                            value={title}
                            name="title"
                            className="border-gray-500 border-2 w-full mb-3 h-[3rem] rounded-md outline-none px-3"
                            placeholder="Service"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <label htmlFor="" className="text-lg font-semibold">
                          Price
                        </label>
                        <br />
                        <input
                          // type="email"
                          onChange={(evnt) => handleChange(index, evnt)}
                          value={price}
                          name="price"
                          className="border-gray-500 border-2 w-full  mb-3  h-[3rem] rounded-md outline-none px-3"
                          placeholder="Price"
                        />
                      </div>
                      <div className="col">
                        <label htmlFor="" className="text-lg font-semibold">
                          Discription
                        </label>
                        <br />
                        <textarea
                          type="text"
                          onChange={(evnt) => handleChange(index, evnt)}
                          value={description}
                          name="description"
                          className="border-gray-500 border-2  mb-3 w-[20rem] h-[10rem] rounded-md outline-none px-3"
                          placeholder="Discription"
                        />
                      </div>
                      <div className=" flex items-center w-fit md:mb-0 mb-2 rounded-lg h-[3rem] bg-red-700 ">
                        {inputFields.length !== 1 ? (
                          <button
                            className="m-5"
                            type="button"
                            onClick={removeInputFields}
                          >
                            Remove
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}

                <div className="row">
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="p-5 flex items-center rounded-lg h-[3rem] text-white bg-green-700 "
                      onClick={addInputField}
                    >
                      Add New
                    </button>

                    <button
                      type="submit"
                      className="py-5 px-7 flex items-center rounded-lg h-[3rem] text-white bg-green-700 "
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4"></div>
          </div>
        </form>
        <ToastContainer />
      </div>
    </SideNav>
  );
}

export default Add_orders