import React, { useContext } from "react";
import SideNav from "../Dashboard/SideNav";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { UserContext } from "../App";

const Chinese = () => {
  axios.defaults.withCredentials = true;
  const user = useContext(UserContext);
  const [val, setVal] = useState("");
  const [charge, setCharge] = useState("");
  const [link, setLink] = useState("");
  const [order_id, set_id] = useState("");
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState("");
  const [hidden, setHidden] = useState("66381e33d5cce60483317a94");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        order_id: hidden,
        link,
        charge: Number(amount),
      };

      const resp = await axios.post(
        "https://backend-production-8016.up.railway.app/orders/create-order",
        data
      );
      //  console.log(resp);
      if (resp.status === 200) {
        toast.success(resp.data.message, {
          position: "top-right",
          autoClose: 100,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.href = "/Dashboard";
        }, 2000);
      }
    } catch (error) {
      //  console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const SubmitDetials = async (e) => {
    e.preventDefault();
    try {
      const data = {
        charge: Number(price),
        link,
        order_id: hidden,
      };
      //  payment_method.value = address
      const resp = await axios.post(
        "https://backend-production-8016.up.railway.app/orders/create-order",
        data
      );
      console.log(resp);
      if (resp.status === 200) {
        toast.success(resp.data.message, {
          position: "top-right",
          autoClose: 100,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.href = "/Dashboard";
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };
  //  if (!user) {
  //    return (
  //      <div className="flex justify-center h-screen items-center">
  //        <div className="text-xl font-semibold">
  //          <p className="text-5xl font-bold">ERROR 404</p>

  //          <p>
  //            Please Re-login With your pc(laptop){" "}
  //            <Link to="/" className="text-blue-600">
  //              click here
  //            </Link>
  //          </p>
  //        </div>
  //      </div>
  //    );
  //  }
  return (
    <>
      <SideNav>
        <div className="MiddleDiv">
          <div className="py-2 md:mb-0 mb-5 md:w-[70vw] md:py-7 pl-2">
            <div className=" md:w-[75vw] md:block md:h-fit mx-2 md:mr-0 mr-5 px-5 md:mt-5 rounded-xl bnb md:px-7 py-5">
              <ToastContainer />
              <div>
                <h1 className="text-xl md:mt-0 mt-5 md:mb-0 mb-5 md:text-2xl font-bold">
                  Chinese Investors
                </h1>
                <p className=" font-semibold mt-2">
                Chinese Investors platform specializes in connecting projects exclusively with Chinese investors interested in purchasing tokens, this are mainly for just chinese investors and envavour to have a chinese community for your project where they can easily engage with the project.
                </p>
                <form
                  className="md:grid md:grid-cols-2 md:w-[100%]"
                  onSubmit={handleSubmit}
                >
                  <section className="mt-2 md:mt-5 md:w-[100%]">
                    <label
                      className="font-bold text-sm md:text-base"
                      htmlFor=""
                    >
                      Project Exchange Link (Buy Link)
                    </label>
                    <br />
                    <input
                      value={link}
                      onChange={(event) => setLink(event.target.value)}
                      placeholder="https://"
                      type="text"
                      className="border-gray-500 border-2 mb-3 outline-none rounded-md px-5 text-sm md:text-ml l w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                    />
                    <br />

                    <label
                      className="font-bold text-sm md:text-base"
                      htmlFor=""
                    >
                      Token Network
                    </label>
                    <br />
                    <select
                      name=""
                      id=""
                      className="border-gray-500 border-2 outline-none mb-3 px-3 rounded-md text-mll w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                    >
                      <option value="">Arbitrum Network Tokens</option>
                      <option value="">Avax Network Tokens</option>
                      <option value="">Binance Network Tokens</option>
                      <option value="">Core Network Tokens</option>
                      <option value="">Ethereum Network Tokens</option>
                      <option value="">Cronos Network Tokens</option>
                      <option value="">Polygon Network Tokens</option>
                    </select>
                    <br />

                    <label
                      className="font-bold text-sm md:text-base"
                      htmlFor=""
                    >
                      Amount of Investors
                    </label>
                    <br />
                    <select
                      type="text"
                      className="border-gray-500 mb-3 border-2 outline-none rounded-md px-2 text-base l w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                      value={amount}
                      onChange={(event) => setAmount(event.target.value)}
                    >
                      <option value="">--- Select Investors ---</option>
                      <option value="250">50 - 70 Chinese Investors</option>
                      <option value="500">100 - 250 Chinese Investors</option>
                      <option value="720">300 - 500 Chinese Investors</option>
                      <option value="1000">500 - 700 Chinese Investors</option>
                      <option value="1500">1000 Chinese Investors</option>
                    </select>
                    <br />

                    <label
                      className="font-bold text-sm md:text-base"
                      htmlFor=""
                    >
                      Price
                    </label>
                    <br />
                    <div
                      className="border-gray-500 border-2 outline-none px-3 rounded-md text-ml 
                    w-[90%] md:w-[80%] mb-3 md:h-[3rem] h-[2.5rem] flex items-center"
                    >
                      <p className="font-semibold mt-[2px]">$</p>
                      <input
                        readOnly
                        // placeholder="All payments made strictly in USD"
                        value={amount}
                        onChange={(event) => setCharge(event.target.value)}
                        name=""
                        id=""
                        className="w-full  outline-none h-full"
                        // readOnly
                      />
                    </div>

                    <label
                      className="font-bold text-sm md:text-base"
                      htmlFor=""
                    >
                      Telegram Chinese Community Link
                    </label>
                    <br />
                    <input
                      type="text"
                      className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                    />
                    <input
                      hidden
                      value={hidden}
                      onChange={(event) => set_id(event.target.value)}
                      type="text"
                      className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                    />
                    <br />
                  </section>

                  <section className="mt-2 md:mt-5">
                    <label
                      htmlFor=""
                      className="font-bold text-sm md:text-base"
                    >
                      Discription
                    </label>
                    <br />
                    <textarea
                      placeholder="What do you want the investors to do  in your community?"
                      className="md:h-[90%] h-[200px] mt-3 w-full border-gray-500 border-2 outline-none rounded-md p-5 text-black"
                    />
                  </section>
                  <button className="bg-[rgb(56,63,104)] mt-2 md:mt-5 w-[30%] text-white px-5 py-2 md:px-7 rounded-md md:py-3 md:text-lg md:mb-0 mb-2">
                    Proceed
                  </button>
                </form>
              </div>
              <b className="flex gap-2 items-center mt-5 md:mt-10 md:text-base text-[13px]">
                <p className="text-yellow-400">Note: </p>
                <p>It takes 2-3 hours to comfirm work completly done</p>
              </b>
            </div>
          </div>
        </div>
      </SideNav>
    </>
  );
};

export default Chinese;
