import React from 'react'
import SideNav from '../Dashboard/SideNav'
import { Link } from 'react-router-dom';
import { FcCancel } from "react-icons/fc";
import { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import { UserContext } from "../App";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
export const OrderContext = createContext();


const Orders = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  axios.defaults.withCredentials = true;
  const user = useContext(UserContext);
  const [orders, setData] = useState([]);
  

  useEffect(() => {
    if (user) {
     
      axios
        .post(
          "https://backend-production-8016.up.railway.app/orders/order/user",
          {
            userid: user._id,
          }
        )
        .then((resp) => {
          if (resp) {
            const sortedData = [...resp.data];
            sortedData.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateB - dateA;
            });

            setData(sortedData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);



     if (!user) {
       return (
         <div className="flex justify-center h-screen items-center">
           <div className="text-xl font-semibold">
             <p className="text-5xl font-bold">ERROR 404</p>

             <p>
               Please Re-login{" "}
               <Link to="/" className="text-blue-600">
                 click here
               </Link>
             </p>
           </div>
         </div>
       );
     }
 



  return (
    <div>
      <SideNav>
        <div className="p-5 md:p-10">
          <p className="md:text-2xl md:mb-7 mb-3 uppercase font-semibold md:ml- ">
            Orders
          </p>
          <div className="border-b-2 border-black pb-5 mb-3 md:mb-7">
            <ul className="md:flex md:w-[80%] justify-between ">
              <section className="flex md:justify-between md:w-[55%] md:gap-0 gap-2 md:mb-0 mb-2">
                <Link to="">
                  <li className="md:px-4 flex justify-center items-center px-2 py-1 md:py-2 rounded-full border-2 border-black ">
                    All
                  </li>
                </Link>
                <Link to="">
                  <li className="md:px-4 flex justify-center items-center px-2 py-1 md:py-2 rounded-full border-2 border-black ">
                    Pending
                  </li>
                </Link>
                <Link to="">
                  <li className="md:px-4 flex justify-center items-center px-2 py-1 md:py-2 rounded-full border-2 border-black ">
                    In Progess
                  </li>
                </Link>
                <Link to="">
                  <li className="md:px-4 flex justify-center items-center px-2 py-1 md:py-2 rounded-full border-2 border-black ">
                    Completed
                  </li>
                </Link>
              </section>

              <section className="flex md:justify-between md:gap-0 gap-2 md:w-[42%] md:ml-0 ml-5">
                <Link to="">
                  <li className="md:px-4 flex justify-center items-center py-1 px-2 md:py-2 rounded-full border-2 border-black ">
                    Partial
                  </li>
                </Link>
                <Link to="">
                  <li className="md:px-4 flex justify-center items-center px-2 py-1 md:py-2 rounded-full border-2 border-black ">
                    Processing
                  </li>
                </Link>
                <Link to="">
                  <li className="md:px-4 flex justify-center items-center px-2 py-1 md:py-2 rounded-full border-2 border-black ">
                    Canceled
                  </li>
                </Link>
              </section>
            </ul>
          </div>

          {orders.length > 0 ? (
            <div>
              <>
                <TableContainer component={Paper} className=" ">
                  <Table
                    sx={{ minWidth: 700, maxWidth: 1000 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Project Link</StyledTableCell>
                        <StyledTableCell align="right">
                          Order_category / Investors
                        </StyledTableCell>
                        <StyledTableCell align="right">Charge</StyledTableCell>
                        {/* <StyledTableCell align="right">
                              user
                            </StyledTableCell> */}
                        <StyledTableCell align="right">Date</StyledTableCell>
                        {/* <StyledTableCell align="right">
                              status
                            </StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.map((items) => (
                        <StyledTableRow key={orders.name}>
                          <StyledTableCell component="th" scope="row">
                            {items?.link}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {items?.order_category_id?.title}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            ${items.charge}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">
                                {items?.user.username}
                              </StyledTableCell> */}
                          <StyledTableCell align="right">
                            {new Date(items?.createdAt).toLocaleDateString(
                              "en-us",
                              {
                                dateStyle: "full",
                              }
                            )}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">
                                {items?.status}
                              </StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
              {/* <div>{orders.length}</div> */}
            </div>
          ) : (
            <div className="m-7 grid items-center justify-center">
              <FcCancel className="ml-14 md:ml-24 h-28 w-28 md:w-36 md:h-36" />
              <h2 className="text-xl md:text-3xl text-center font-bold">
                You have no active orders
              </h2>
            </div>
          )}
        </div>
      </SideNav>
    </div>
  );
}

export default Orders