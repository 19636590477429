import React from 'react'
import { useState, useContext } from "react";
import SideNav from '../Dashboard/SideNav'
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { UserContext } from "../App";

const TelegramServices = () => {
   axios.defaults.withCredentials = true;
   const user = useContext(UserContext); 
const [data, setData] = useState("");
const [order_id, set_id] = useState("");
const [link, setLink] = useState("");
const [charge, setCharge] = useState("");
const [price, setPrice] = useState("");
const [amount, setAmount] = useState("");
const [hidden, setHidden] = useState("66381e17d5cce60483317a84");

 const handleSubmit = async (event) => {
   event.preventDefault();
   try {
     const data = {
       order_id:(hidden),
       link,
       charge:Number(amount),
     };

     const resp = await axios.post(
       "https://backend-production-8016.up.railway.app/orders/create-order",
       data
     );
     //  console.log(resp);
     if (resp.status === 200) {
       toast.success(resp.data.message, {
         position: "top-right",
         autoClose: 100,
         hideProgressBar: true,
         closeOnClick: false,
         pauseOnHover: false,
         draggable: false,
         progress: undefined,
         theme: "light",
       });
          setTimeout(() => {
            window.location.href = "/Dashboard";
          }, 2000);
     }
   } catch (error) {
     //  console.log(error);
     toast.error(error.response.data.message, {
       position: "top-right",
       autoClose: 100,
       hideProgressBar: true,
       closeOnClick: false,
       pauseOnHover: false,
       draggable: false,
       progress: undefined,
       theme: "light",
     });
   }
  };
  

 const SubmitDetials = async (event) => {
   event.preventDefault();
   try {
     const data = {
       order_id:(hidden),
       link,
       charge:Number(price),
     };

     const resp = await axios.post(
       "https://backend-production-8016.up.railway.app/orders/create-order",
       data
     );
     //  console.log(resp);
     if (resp.status === 200) {
       toast.success(resp.data.message, {
         position: "top-right",
         autoClose: 100,
         hideProgressBar: true,
         closeOnClick: false,
         pauseOnHover: false,
         draggable: false,
         progress: undefined,
         theme: "light",
       });
          setTimeout(() => {
            window.location.href = "/Dashboard";
          }, 2000);
     }
   } catch (error) {
     //  console.log(error);
     toast.error(error.response.data.message, {
       position: "top-right",
       autoClose: 100,
       hideProgressBar: true,
       closeOnClick: false,
       pauseOnHover: false,
       draggable: false,
       progress: undefined,
       theme: "light",
     });
   }
  };
    //  if (!user) {
    //    return (
    //      <div className="flex justify-center h-screen items-center">
    //        <div className="text-xl font-semibold">
    //          <p className="text-5xl font-bold">ERROR 404</p>

    //          <p>
    //            Please Re-login With your pc(laptop){" "}
    //            <Link to="/" className="text-blue-600">
    //              click here
    //            </Link>
    //          </p>
    //        </div>
    //      </div>
    //    );
    //  }

      // const [val, setVal] = useState("");
      // const [amount, setPrice] = useState("");
  return (
    <SideNav>
      <div className="MiddleDiv">
        <div className="py-2 md:w-[70vw] md:pt-7 pl-2 ">
          <div className="md:w-[75vw] mb-7 md:mb-0 md:block md:h-fit md:mr-0 mr-5 mx-2 px-5 md:mt-5 rounded-xl bnb md:px-7 py-5">
            <div>
              <h1 className="text-xl md:mt-0 mt-5 md:mb-0 mb-5 md:text-2xl font-bold">
                Global Investors
              </h1>
              <p className=" font-semibold mt-2">
                The global investors platform connects legitimate buyers and
                investors worldwide. Once you place your order, your project
                undergoes review to ensure it's safe for our investors. It's one
                of the quickest and most reliable ways to make purchases.
              </p>
              <form
                className="md:grid md:grid-cols-2 md:w-[100%]"
                onSubmit={handleSubmit}
              >
                <section className="mt-2 md:mt-5 md:w-[100%]">
                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Project Exchange Link (Buy Link)
                  </label>
                  <br />
                  <input
                    value={link}
                    onChange={(event) => setLink(event.target.value)}
                    placeholder="https://"
                    type="text"
                    className="border-gray-500 border-2 mb-3 outline-none rounded-md px-5 text-sm md:text-ml l w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                  />
                  <br />

                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Token Network
                  </label>
                  <br />
                  <select
                    name=""
                    id=""
                    className="border-gray-500 border-2 outline-none mb-3 px-3 rounded-md text-mll w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                  >
                    <option value="">Arbitrum Network Tokens</option>
                    <option value="">Avax Network Tokens</option>
                    <option value="">Binance Network Tokens</option>
                    <option value="">Core Network Tokens</option>
                    <option value="">Ethereum Network Tokens</option>
                    <option value="">Cronos Network Tokens</option>
                    <option value="">Polygon Network Tokens</option>
                  </select>
                  <br />

                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Amount of Investors
                  </label>
                  <br />
                  <select
                    type="text"
                    className="border-gray-500 mb-3 border-2 outline-none rounded-md px-2 text-base l w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                  >
                    <option value="">--- Select Investors ---</option>
                    <option value="200">50 - 70 Assured Investors</option>
                    <option value="370">100 - 250 Assured Investors</option>
                    <option value="550">300 - 500 Assured Investors</option>
                    <option value="800">500 - 700 Assured Investors</option>
                    <option value="1100">1000 Assured Investors</option>
                  </select>
                  <br />

                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Price
                  </label>
                  <br />
                  <div
                    className="border-gray-500 border-2 outline-none px-3 rounded-md text-ml 
                    w-[90%] md:w-[80%] mb-3 md:h-[3rem] h-[2.5rem] flex items-center"
                  >
                    <p className="font-semibold mt-[2px]">$</p>
                    <input
                      readOnly
                      // placeholder="All payments made strictly in USD"
                      value={amount}
                      onChange={(event) => setCharge(event.target.value)}
                      name=""
                      id=""
                      className="w-full  outline-none h-full"
                      // readOnly
                    />
                  </div>

                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Telegram Link
                  </label>
                  <br />
                  <input
                    type="text"
                    className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                  />
                  <input
                    hidden
                    value={hidden}
                    onChange={(event) => set_id(event.target.value)}
                    type="text"
                    className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                  />
                  <br />
                </section>

                <section className="mt-2 md:mt-5">
                  <label htmlFor="" className="font-bold text-sm md:text-base">
                    Discription
                  </label>
                  <br />
                  <textarea
                    placeholder="What do you want the investors to do  in your community?"
                    className="md:h-[90%] h-[200px] mt-3 w-full border-gray-500 border-2 outline-none rounded-md p-5 text-black"
                  />
                </section>
                <button className="bg-[rgb(56,63,104)] mt-2 md:mt-5 w-[30%] text-white px-5 py-2 md:px-7 rounded-md md:py-3 md:text-lg md:mb-0 mb-2">
                  Proceed
                </button>
              </form>
            </div>
            <b className="flex gap-2 items-center md:mt-10 md:text-base text-[13px]">
              <p className="text-yellow-400">Note: </p>
              <p>It takes 2-3 hours to comfirm work completly done</p>
            </b>
          </div>
        </div>
        <ToastContainer />
        {/* <div className="py-2 md:w-[75vw] md:pt-7 pl-2">
          <div className=" md:w-[75vw] md:block mr-5 mb-7 md:mb-0 md:h-fit mx-2 px-5 md:mt-5 rounded-xl bnb md:pl-7 py-5">
            <form onSubmit={SubmitDetials}>
              <h1 className="text-base md:text-xl pb-2 font-bold">
                Telegram Chinese Channel Investors
              </h1>
              <div className="md:grid md:grid-cols-2 md:w-[100%]">
                <section className="mt-2 md:mt-5">
                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Select Chinese Channel
                  </label>
                  <br />
                  <input
                    hidden
                    value={hidden}
                    onChange={(event) => set_id(event.target.value)}
                    type="text"
                    className="border-gray-500 border-2 outline-none rounded-md px-5 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                  />
                  <select
                    value={price}
                    onChange={(event) => setPrice(event.target.value)}
                    name=""
                    id=""
                    className="border-gray-500 mb-3  border-2 outline-none px-3 rounded-md text-ml 
                    w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                  >
                    <option> --- Select a Channel ---</option>
                    <option value="200">
                      Crypto Gems - [23K Subscribers] -
                      https://t.me/cryptogems55552{" "}
                    </option>
                    <option value="470">
                      🐳CODY WHALES CALL 中國🐳 - [57.4K Subscribers] -
                      https://t.me/CODYWHALESCALLS{" "}
                    </option>
                    <option value="220">
                      加密法师匚卄丨几乇丂乇🧧☎️🏯 - [24.5K Subscribers] -
                      https://t.me/cryptogems55552{" "}
                    </option>
                    <option value="170">
                      ☎️CHINESE GEM CALL🇨🇳🍯 - [21K Subscribers] -
                      https://t.me/eth_gem_calls{" "}
                    </option>
                    <option value="80">
                      Kenelm calls｜CN MKT✨ - [8.5K Subscribers] -
                      https://t.me/kenelmcalls{" "}
                    </option>
                    <option value="180">
                      🇨🇳Chinese_Calls🇨🇳 - [21.9K Subscribers] -
                      https://t.me/HualiuEN{" "}
                    </option>
                  </select>
                  <br />

                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Telegram Link
                  </label>
                  <br />
                  <input
                    value={link}
                    onChange={(event) => setLink(event.target.value)}
                    placeholder="https://"
                    type="text"
                    className="border-gray-500 border-2 outline-none rounded-md px-3 text-base
                     w-[90%] md:w-[80%] md:h-[3rem] mb-3 h-[2.5rem]"
                  />
                  <br />

                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Charge
                  </label>
                  <br />
                  <div
                    className="border-gray-500 border-2 outline-none rounded-md px-3 text-base
                     w-[90%] md:w-[80%] mb-3 md:h-[3rem] h-[2.5rem] flex items-center"
                  >
                    <p className="font-semibold mt-[2px]">$</p>
                    <input
                      readOnly
                      // placeholder="All payments made strictly in USD"
                      value={price}
                      onChange={(event) => setCharge(event.target.value)}
                      name=""
                      id=""
                      className="w-full   outline-none h-full"
                      // readOnly
                    />
                  </div>
                </section>

                <section className="w-full">
                  <label className="font-bold text-sm md:text-base" htmlFor="">
                    Project Description/Tempelate
                  </label>
                  <textarea
                    placeholder="Write a description about your project or what you want us to post on our chinese telegram community to fetch you more chinese investors..."
                    className="border-gray-500 border-2 h-[18rem] py-5 outline-none rounded-md px-5 text-base w-full md:w-[100%] md:h-full"
                  />

                  <button className="bg-[rgb(56,63,104)] mt-2 md:mt-0 text-white px-5 py-2 md:px-7 rounded-md md:py-3 md:text-lg md:mb-0">
                    Proceed
                  </button>
                </section>
              </div>
            </form>
            <b className="flex gap-2 items-center mt-2 md:my-5 md:text-base text-[13px]">
              <p className="text-yellow-400">Note: </p>
              <p>It takes 5 minutes to comfirm work completly done</p>
            </b>
          </div>
        </div> */}

        {/* <div className="md:w-[75vw] md:block md:mr-0 mr-5 mb-5 md:h-fit mx-2 ml-4 px-5 md:my-5 rounded-xl bnb md:px-7 py-5">
          <div>
            <h1 className="text-base md:text-xl pb-2 font-bold">
              Presale Investors (Buyers of Tokens/Coins)
            </h1>
            <div className="md:grid md:grid-cols-2 md:w-[100%]">
              <section className="mt-2 md:mt-5 md:w-[100%]">
                <label className="font-bold text-sm md:text-base" htmlFor="">
                  Pinksale Link
                </label>
                <br />
                <input
                  placeholder="https://"
                  type="text"
                  className="mb-3 border-gray-500 border-2 outline-none rounded-md px-5 text-sm md:text-mll w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                />
                <br />

                <label className="font-bold text-sm md:text-base" htmlFor="">
                  Service
                </label>
                <br />
                <select
                  name=""
                  id=""
                  className="border-gray-500 border-2 outline-none mb-3 px-3 rounded-md  w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                >
                  <option value="$100">
                    Soft cap 5bnb - 15bnb @ $100 Fillfull
                  </option>
                  <option value="$250">
                    Soft cap 20bnb - 40bnb @ $250 Fillfull
                  </option>
                  <option value="$550">
                    Soft cap 50bnb - 70bnb @ $550 Fillfull
                  </option>
                  <option value="$800">
                    Soft cap 70bnb - 100bnb @ $800 Fillfull
                  </option>
                </select>
                <br />

                <label className="font-bold text-sm md:text-base" htmlFor="">
                  Price's
                </label>
                <br />
                <input
                  placeholder="All payments made strictly in USD"
                  readOnly
                  type="text"
                  className="border-gray-500 border-2 outline-none rounded-md px-5 text-base 
                  w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                />
              </section>

              <section className="mt-2 md:mt-5">
                <label className="font-bold text-sm md:text-base" htmlFor="">
                  Telegram Group Link
                </label>
                <br />

                <input
                  name=""
                  id=""
                  className="border-gray-500 border-2 outline-none px-3 rounded-md w-[90%] 
                  md:w-[80%] md:h-[3rem] h-[2.5rem]"
                />

                <br />
                <br />

                <label className="font-bold text-sm md:text-base" htmlFor="">
                  Average Time
                </label>
                <br />
                <input
                  type="text"
                  className="border-gray-500 border-2 outline-none rounded-md px-5 text-base 
                   w-[90%] md:w-[80%] md:h-[3rem] h-[2.5rem]"
                />
                <br />

                <button className="bg-[rgb(56,63,104)] mt-2 md:mt-5 text-white px-5 py-2 md:px-7 rounded-md md:py-3 md:text-lg md:mb-0 mb-2">
                  Proceed
                </button>
              </section>
            </div>
          </div>
          <b className="flex gap-2 items-center md:mt-10 md:text-base text-[13px]">
            <p className="text-yellow-400">Note: </p>
            <p>It takes 2-3 hours to comfirm work completly done</p>
          </b>
        </div> */}
      </div>
    </SideNav>
  );
}

export default TelegramServices